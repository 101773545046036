.redes-sociais {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  width: 100%;
  overflow: hidden;
}

.redes-sociais p {
  display: flex;
  margin: 0 auto;
  color: #fff;  
  font-family: "Roboto Flex"; 
  font-weight: 400;
  font-size: 22px;
}

.redes-sociais h3 {
  display: flex;
  margin: 0 auto;
  color: #fff;
  font-size: 30px;    
  font-family: 'HurinPersonalUseOnly'; 
  letter-spacing: 1.5px;
}

.social-icons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border: 2px solid #4646461a;
  border-radius: 25px;
  margin: 30px;
  padding: 20px 175px;
  text-align: center;
  color: #fff;
  line-height: 1.1;
  letter-spacing: -0.5px;
  box-shadow:
    0 4px 8px rgba(0, 0, 0, 0.3),
    4px 0 8px rgba(0, 0, 0, 0.082),
    -4px 0 8px rgba(0, 0, 0, 0.082),
    0 -4px 8px rgba(255, 255, 255, 0.185);
}

.social-icons .user {
  display: flex;
  margin: 0 auto;
}

.social-icon {
  width: 32px;
  height: 32px;
  margin: 0 20px;
  fill: #6cc60f;
  animation: pulse 1.5s infinite;
}

/* Media queries para responsividade */
@media (max-width: 768px) {
  .redes-sociais {
    margin: 40px 0;
  }
  
  .redes-sociais p {
    font-size: 18px;
  }

  .redes-sociais h3 {
    font-size: 25px;
  }

  .social-icons {
    flex-direction: row;
    padding: 20px 100px;
  }

  .social-icon {
    margin: 10px 25px;
  }
}

@media (max-width: 480px) {
  .redes-sociais {
    margin: 50px 0;
  }

  .redes-sociais p {
    font-size: 16px;
  }

  .redes-sociais h3 {
    font-size: 18px;
  }

  .social-icons {
    flex-direction: row;
    padding: 15px 10px;
  }

  .social-icon {
    margin: 5px 15px;
  }
}
