.bannerGamer {
  background-color: #96d31b;
  border-radius: 50px;
  margin: 0 auto;
  padding: 65px 0;
  height: auto;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  z-index: 1000;
}

.bannerGamer .corporativos {
  width: 90%;
  margin: 75px auto 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-align: center;
}

.bannerGamer .smartphones {
  width: 90%;
  margin: 20px auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-align: center;
}

.bannerGamer .corporativos .conteudo-esquerda,
.bannerGamer .smartphones .conteudo-direita {
  width: 45%;
}

.bannerGamer .corporativos .conteudo-direita,
.bannerGamer .smartphones .conteudo-esquerda {
  width: 45%;
}

.texto-header {
  color: rgb(1, 67, 167);
  font-size: 65px;
  text-align: start;
  margin-left: 100px;
  font-family: "Roboto Flex";
  font-weight: 600;
}

.texto-p {
  margin-top: -50px;
  font-size: 16px;
  color: rgb(1, 67, 167);
  text-align: start;
  margin-left: 100px;
  font-family: "Roboto Flex";
  font-weight: 400;
}

.monitor-corp {
  max-width: 60%;
  height: auto;
  top: 55px;
  right: 50px;
  object-fit: contain;
  position: relative;
  transition: transform 0.3s ease;
}

.celular {
  max-width: 40%;
  height: auto;
  top: 120px;
  left: 0;
  object-fit: contain;
  position: relative;
  transition: transform 0.3s ease;
}

.monitor-corp:hover,
.celular:hover {
  transform: scale(1.1);
}

@media (max-width: 768px) {
  .bannerGamer .corporativos,
  .bannerGamer .smartphones {
    flex-direction: column;
  }

  .bannerGamer .corporativos .conteudo-esquerda {
    order: 1;
    width: 100%;
    text-align: center;
    margin-left: 0;
  }

  .bannerGamer .corporativos .conteudo-direita {
    order: 2;
    width: 100%;
    text-align: center;
    margin-left: 0;
  }

  .bannerGamer .smartphones .conteudo-esquerda {
    order: 2;
    width: 100%;
    text-align: center;
    margin-left: 0;
  }

  .bannerGamer .smartphones .conteudo-direita {
    order: 1;
    width: 100%;
    text-align: center;
    margin-left: 0;
  }

  .celular {
    max-width: 45%;
  }

  .bannerGamer .texto-header {
    font-size: 48px;
  }

  .bannerGamer .texto-p {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .bannerGamer {
    padding: 30px 0;
  }

  .bannerGamer .corporativos {
    padding: 10px 0 80px 0;
  }

  .bannerGamer .texto-header {
    font-size: 36px;
    margin: 50px 50px 0 50px;
  }

  .bannerGamer .texto-p {
    font-size: 12px;
    margin: 0 50px 50px 50px;
  }

  .monitor-corp {
    right: 0;
  }

  .celular {
    max-width: 75%;
  }
}
