.computers {
  display: flex;
  flex-direction: row;
  border: 2px solid #96d31b;
  border-radius: 25px;
  font-size: 72px;
  font-family: 'AcuminProExtraCondBold', sans-serif;
  padding: 65px;
  margin: 20px;
  text-align: justify;
  color: #fff;
  line-height: 1.1;
  letter-spacing: -0.5px;
}

.computers .item-esquerda {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.computers .item-direita {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.computers .item-esquerda .teclado {
  height: 300px;
  width: auto;
  transition: transform 0.3s ease;
}

.teclado:hover {
  transform: scale(1.1);
}

.computers p {
  font-size: 40px;
  color: #fff;
  font-family: "Roboto Flex"; 
  font-weight: 400;
  letter-spacing: -1px;
}

.computers .internet {
  height: 30px;
  width: 30px;
  animation: moveAndRotateGame 3s infinite;
}

.computers .internet2 {
  height: 30px;
  width: 30px;
  animation: moveAndRotateGameController 3s infinite;
}

@media (max-width: 768px) {
  .computers {
    flex-direction: column;
    font-size: 48px;
    padding: 40px;
  }

  .computers .item-esquerda, .computers .item-direita {
    width: 100%;
    margin-bottom: 20px;
  }

  .computers p {
    font-size: 30px;
  }

  .computers .item-esquerda .teclado {
    height: 200px;
  }
}

@media (max-width: 480px) {
  .computers {
    padding: 20px;
    font-size: 36px;
  }

  .computers p {
    font-size: 20px;
    letter-spacing: -1px;
    word-spacing: -0.5px;
  }

  .computers .item-esquerda .teclado {
    height: 150px;
  }

  .computers .internet, .computers .internet2 {
    height: 15px;
    width: 15px;
  }
}
