.grid {
  position: relative;
  z-index: 1;
  bottom: -75px;
}

.grid .texto-borda {
  display: flex;
  flex-direction: row;
  border: 2px solid #96d31b;
  border-radius: 80px;
  padding: 25px 50px;
  margin: 50px 50px;
  font-size: 72px;
  color: #fff;
  line-height: 1.1;
  letter-spacing: -0.5px;
  flex-wrap: wrap; /* Adicionado para permitir a quebra de linha */
}

.grid .texto-borda .box-grid {
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  min-width: 250px; /* Define a largura mínima para os itens */
}

.grid .texto-borda .texto-grid {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
}

.grid .texto-borda .box-grid img {
  height: 65px;
  width: auto;
  margin: 20px 10px 0 0;
}

.grid .texto-borda h4 {
  font-size: 28px;
  color: #96d31b;
  font-family: "Roboto Flex";
  font-weight: 500;
}

.grid .texto-borda p {
  font-size: 18px;
  padding-bottom: 40px;
  font-family: "Roboto Flex";
  font-weight: 400;
  text-align: start;
  margin: -30px 0 0 0;
}

.grid .img-background {
  background: url('../../../assets/fundo-grid.webp') no-repeat center center;
  background-size: cover;
  position: relative;
  height: 190vh;
  width: 100%;
  margin-top: 100px;
}

.grid .container {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
}

.grid .dual {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; /* Adicionado para permitir a quebra de linha */
}

.grid .box {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #96d31b;
  height: 300px;
  width: 1000px;
  padding: 25px;
  border-radius: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease;
}

.grid .box:hover {
  transform: scale(1.1);
  z-index: 10;
}

.content h1 {
  font-size: 45px;
  color: rgb(1, 67, 167);
  font-family: "Roboto Flex";
  font-weight: bold;
}

.content p {
  font-size: 20px;
  color: rgb(1, 67, 167);
  font-family: "Roboto Flex";
  font-weight: 400;
  margin-top: -35px;
}

.content {
  flex: 1;
}

.image {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.image img {
  max-width: 300px;
  top: 25px;
  left: 250px;
  object-fit: contain;
  position: absolute;
}

.background-frase {
  position: relative;
  height: 65vh;
  width: 100%;
  margin-top: 100px;
}

.frase {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0 350px;
  text-align: center;
  color: #fff;
  font-size: 30px;
  font-family: 'HurinPersonalUseOnly';
  font-weight: 400;
}

.grid .aorus {
  display: flex;
  width: 50%;
  height: auto;
  margin: 0 auto;
  padding-bottom: 75px;
}

@media (max-width: 1024px) {
  .grid .box {
    flex-direction: row;
    height: auto;
    height: 250px;
    width: 50%;
  }

  .image img {
    max-width: 150px;
    top: 55px;
    left: 150px;
  }

  .content h1 {
    font-size: 32px;
  }

  .content p {
    font-size: 16px;
  }

  .background-frase {
    height: 35vh;
    width: 100%;
    margin-top: 50px;
    background-size: 125%;
  }

  .frase {
    padding: 0 100px;
    font-size: 24px;
  }
}

@media (max-width: 768px) {
  .grid .texto-borda {
    flex-direction: column;
    padding: 20px;
    margin: 20px;
    font-size: 36px;
  }

  .grid .texto-borda .box-grid {
    flex-direction: column;
    align-items: center;
  }

  .grid .texto-borda .texto-grid {
    align-items: center;
    text-align: center;
  }

  .grid .container {
    flex-direction: row;
    gap: 10px;
  }

  .grid .img-background {
    background: url('../../../assets/fundo-grid.webp') no-repeat bottom center;
    background-size: 120%;
    position: relative;
    height: 160vh;
    width: 100%;
    margin-top: 100px;
  }

  .grid .dual {
    flex-direction: row;
    gap: 10px;
  }

  .grid .box {
    flex-direction: row;
    height: auto;
    height: 250px;
    width: 50%;
  }

  .image img {
    max-width: 150px;
    top: 60px;
    left: 150px;
  }

  .content h1 {
    font-size: 24px;
  }

  .content p {
    font-size: 14px;
  }

  .background-frase {
    overflow: hidden;
    height: 50vh;
  }

  .frase {
    padding: 0 20px;
    font-size: 20px;
  }
}

@media (max-width: 480px) {
  .grid .texto-borda h4 {
    font-size: 28px;
    text-align: center;
  }

  .grid .texto-borda p {
    text-align: center;
  }

  .grid .container {
    flex-direction: column;
    gap: 5px;
  }

  .grid .img-background {
    background: url('../../../assets/fundo-grid.webp') no-repeat bottom center;
    background-size: 120%;
    position: relative;
    height: 340vh;
    width: 100%;
    margin-top: 100px;
  }

  .grid .dual {
    align-items: center;
    gap: 5px;
  }

  .grid .box {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
    background-color: #96d31b;
    height: 400px;
    width: 70%;
    padding: 25px 75px 0 75px;
    border-radius: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease;
    z-index: 1;
  }

  .image img {
    max-width: 225px;
    top: 235px;
    left: 75px;
  }

  .content h1 {
    font-size: 26px;
    text-align: start;
  }

  .content p {
    font-size: 16px;
    margin-top: -30px;
    text-align: start;
  }

  .background-frase {
    overflow: hidden;
    height: 50vh;
  }

  .frase {
    font-size: 22px;
    margin-top: -40px;
    padding: 0 50px 0 50px;
    text-align: start;
  }

  .grid .aorus {
    display: none;
  }
}
