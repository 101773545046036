nav {
    width: 95%;
    max-width: 788px;
    display: flex;
    justify-content: flex-start;
    padding: 12px;
    margin-top: 15px;
  }

nav img {
  height: 50px;
  width: auto;
}

@media (max-width: 768px) {
  nav {
    justify-content: center;
  }
}

@media (max-width: 480px) {
  nav {
    justify-content: center;
    margin-top: 5px;
  }
}