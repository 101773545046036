.comeceAgora {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 2px solid #4646461a;     
    border-radius: 25px;
    padding: 100px;
    margin: 20px;
    text-align: center;
    color: #fff;
    line-height: 1.1;
    letter-spacing: -0.5px;
    box-shadow: 
        0 4px 8px rgba(0, 0, 0, 0.3),
        4px 0 8px rgba(0, 0, 0, 0.082),
        -4px 0 8px rgba(0, 0, 0, 0.082),
        0 -4px 8px rgba(255, 255, 255, 0.185);
}

.comeceAgora h1 {
    font-size: 40px;
    margin: 0 auto;  
    font-family: 'HurinPersonalUseOnly';   
    letter-spacing: 1px;
}

.comeceAgora h2 {
    color: #fff;
    font-size: 40px;  
    font-family: "Roboto Flex"; 
    font-weight: 400;
}

.comeceAgora button {
    font-family: "Roboto Flex"; 
    font-weight: 400;
    font-size: 26.6px;
    color: #fff;
    background-color: #6cc60f;
    padding: 1em 6em;
    border: solid rgb(1, 67, 167);
    border-radius: 50px;
    position: relative;
    cursor: pointer;
    overflow: hidden;
    display: flex;
    margin: 0 auto;  
    white-space: nowrap;
    animation: pulse 1.5s infinite;
}

/* Media queries para responsividade */
@media (max-width: 768px) {
    .comeceAgora {
        padding: 40px;
    }

    .comeceAgora h1, .comeceAgora h2 {
        font-size: 30px;
    }

    .comeceAgora button {
        font-size: 20px;
        padding: 0.8em 2.5em;
    }
}

@media (max-width: 480px) {
    .comeceAgora {
        padding: 40px 10px;
    }

    .comeceAgora h1, .comeceAgora h2 {
        font-size: 20px;
        margin: 0 50px 0 50px;
    }

    .comeceAgora button {
        font-size: 14px;
        padding: 0.8em 2em;
        margin: 20px 0;
    }
}
