@font-face {
  font-family: 'HurinPersonalUseOnly';
  src: url('../../../assets/fonts/hurinpersonaluseonly.ttf') format('truetype');
}

.intro {
  z-index: 1;
  background: url('../../../assets/textura-banner-inicial.webp') no-repeat center center;
  background-size: cover;
  overflow: hidden;
  margin-top: -75px;
}

.intro h1 {
  z-index: 999;
  text-align: center;
  font-size: 77px;
  color: #fff;
  font-family: 'HurinPersonalUseOnly';
  font-weight: 600;
  letter-spacing: 1px;
}

.intro .top-icons {
  display: flex;
  width: 95%;
  justify-content: space-around;
  align-items: center;
  margin: 0 auto;
  transform: translateY(135px);
}

.intro .bottom-icons {
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
  margin: 0 auto;
  transform: translateY(-50px);
}

.intro .game {
  height: 100px;
  width: 100px;
  animation: moveAndRotateGame 3s infinite;
}

.intro .gameController {
  height: 100px;
  width: 100px;
  animation: moveAndRotateGameController 3s infinite;
}

.intro .ghost {
  left: 18%;
  height: 100px;
  width: 100px;
  animation: moveAndRotateGhost 3s infinite;
}

.intro .laptop {
  right: 18%;
  height: 100px;
  width: 100px;
  animation: moveAndRotateLaptop 3s infinite;
}

.highlight-text {
  position: relative;
  z-index: 1;
}

.highlight {
  color: #6cc60f;
}

.intro button {
  font-family: "Roboto Flex";
  font-weight: 400;
  font-size: 26.6px;
  color: #fff;
  background-color: #96d31b;
  padding: 1em 6em;
  border: solid rgb(1, 67, 167);
  border-radius: 50px;
  position: relative;
  cursor: pointer;
  overflow: hidden;
  display: flex;
  margin: 0 auto;
  white-space: nowrap;
  animation: pulse 1.5s infinite;
}

@media (max-width: 768px) {
  .intro {
    padding: 30px;
    margin-top: -5px;
    background-size: 125%;
  }

  .intro h1 {
  font-size: 35px;
  padding: 0 50px;
  }

  .intro .top-icons {
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: center;
    margin: 0 auto;
    transform: translateY(45px);
    gap: 125px;
  }

  .intro .bottom-icons {
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: center;
    transform: translateY(-75px);
    gap: 275px;
  }

  .intro .game,
  .intro .gameController,
  .intro .ghost,
  .intro .laptop {
    height: 50px;
    width: 50px;
  }

  .intro button {
    padding: 0.5em 3em;
    font-size: 22px;
  }
}

@media (max-width: 480px) {
  .intro {
    background: url('../../../assets/textura-banner-inicial.webp') no-repeat top center;
    background-size: 250%;
    overflow: hidden;
    margin-top: -15px;
    height: 90vh;
  }

  .intro h1 {
  font-size: 34px;
  padding: 0 35px;
  text-align: center;
  }

  .intro .top-icons {
    display: flex;
    width: 100%;
    transform: translateY(5px);
    gap: 200px;
  }

  .intro .bottom-icons {
    display: flex;
    width: 100%;
    transform: translateY(-30px);
    gap: 225px;
  }

  .intro .game,
  .intro .gameController,
  .intro .ghost,
  .intro .laptop {
    height: 40px;
    width: 40px;
  }

  .intro button {
    padding: -1em 2em;
    font-size: 18px;
  }
}
