.banner {
  background-color: #96d31b;
  border-radius: 50px;
  margin: 25px auto;
  padding: 65px 0;
  height: auto;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
}

.banner .notebooks,
.banner .gamer {
  width: 90%;
  margin: 20px auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  text-align: center;
}

.banner .notebooks .conteudo-esquerda,
.banner .gamer .conteudo-direita {
  width: 45%;
}

.banner .notebooks .conteudo-direita,
.banner .gamer .conteudo-esquerda {
  width: 45%;
}

.banner .texto-header {
  color: rgb(1, 67, 167);
  font-size: 65px;
  text-align: start;
  margin-left: 100px;  
  font-family: "Roboto Flex"; 
  font-weight: 600;
}

.banner .texto-p {
  margin-top: -50px;
  font-size: 16px;
  color: rgb(1, 67, 167);
  text-align: start;
  margin-left: 100px;
  font-family: "Roboto Flex"; 
  font-weight: 400;
}

.notebook {
  max-width: 75%;
  height: auto;
  top: 25px;
  right: 50px;
  object-fit: contain;
  position: relative;
  transition: transform 0.3s ease;
}

.gabinete {
  max-width: 65%;
  height: auto;
  top: 145px;
  left: 0;
  object-fit: contain;
  position: relative;
  transition: transform 0.3s ease;
}

.notebook:hover,
.gabinete:hover {
  transform: scale(1.1);
}

@media (max-width: 768px) {
  .banner .notebooks,
  .banner .gamer {
    flex-direction: column;
  }

  .banner .notebooks .conteudo-esquerda {
    order: 1;
    width: 100%;
    text-align: center;
    margin-left: 0;
  }

  .banner .notebooks .conteudo-direita {
    order: 2;
    width: 100%;
    text-align: center;
    margin-left: 0;
  }

  .banner .gamer .conteudo-esquerda {
    order: 2;
    width: 100%;
    text-align: center;
    margin-left: 0;
  }

  .banner .gamer .conteudo-direita {
    order: 1;
    width: 100%;
    text-align: center;
    margin-left: 0;
  }

  .gabinete {
    max-width: 45%;
  }

  .banner .texto-header {
    font-size: 48px;
  }

  .banner .texto-p {
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .banner {
    margin: 50px 0;
    padding: 30px 0;
  }

  .banner .notebooks {
    padding: 10px 0 80px 0;
  }

  .banner .texto-header {
    font-size: 36px;
    margin: 50px 50px 0 50px;
  }

  .banner .texto-p {
    font-size: 12px;
    margin: 0 50px 50px 50px;
  }

  .notebook {
    right: 0;
  }
  
  .gabinete {
    max-width: 75%;
  }
}
