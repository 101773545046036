.logo {
    position: relative;
    height: 50vh;
    margin: 100px auto;
    overflow: hidden;
}

.logo img {
    display: flex;
    align-items: center;
    margin: 75px auto;
    z-index: 100;
    position: relative;
}

@keyframes movePacman {
    0% {
        left: -100px;
    }
    100% {
        left: 100%;
    }
}

.pacman {
    z-index: 1;
    position: absolute;
    top: 35%;
    height: 75px;
    width: 75px;
    background: url('../../../assets/pacman-classic.gif') no-repeat;
    background-size: contain;
    animation: movePacman 5s linear infinite;
    background-color: #00000000;
    overflow: hidden;
}

@media (max-width: 768px) {
    .logo{
        height: 30vh;
    }

    .logo img {
        width: 50%;
        height: auto;
    }

    .pacman {
    top: 34%;
    }
}

@media (max-width: 480px) {
    .logo{
        height: 40vh;
        margin: 0 auto;
    }

    .logo img {
        width: 50%;
        height: auto;
        margin: 0 auto;
    }

    .pacman {
        top: 5%;
        }
}