.body {
    margin: 0;
    padding: 0;
    background-color: rgb(1, 67, 167);
    color: rgb(240, 240, 240);
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100vw;
    font-family: Verdana, Tahoma, sans-serif;
}

.header {
    width: 95%;
    max-width: 788px;
    display: flex;
    justify-content: flex-end;
    padding: 12px;
    margin-top: 15px;
}

.share-button {
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background-color: rgb(240, 240, 240);
}

.share-button svg {
    margin-left: 11px;
    margin-top: 11px;
    color: rgb(0, 0, 0);
}

.container {
    width: 91%;
    max-width: 680px;
    margin: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

h1 {
    font-weight: bold;
    font-size: 22px;
    font-family: "Roboto Flex"; 
    margin-bottom: 30px;
}

a {
    text-decoration: none;
    color: rgb(240, 240, 240);
    font-weight: 400;
    font-size: 18px;
    font-family: "Roboto Flex"; 
}

.tile {
    width: 100%;
    background-color: rgb(37, 37, 37);
    margin: 7px;
    border-radius: 17px;
    display: flex;
    justify-content: space-between;    
    box-shadow: 3px 3px 0 0 #28a745;
}

.tile:hover {
    transition: cubic-bezier(0.7, 1.41, .82, 1.41) 0.2s;
    transform: scale(1.02);
}

.tile-share-button {
    margin: 8px;
    width: 40px;
    height: 40px;
    border-radius: 20px;
    background-color: rgb(52, 52, 52);
}

.tile-share-button svg {
    margin-left: 11px;
    margin-top: 11px;
}

.image-container {
    height: 96px;
    width: 96px;
    border-radius: 48px;
    overflow: hidden;
}

.image-container img {
    height: 100%;
}

.icon {
    margin: 4px 8px;
    width: 44px;
    height: 44px;
}

.alert {    
    font-weight: 500;
    font-size: 20px;
    font-family: "Roboto Flex"; 
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px 20px;
    background-color: #28a745;
    color: white;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    animation: fadeInOut 3s ease-in-out;
  }
  
  @keyframes fadeInOut {
    0%, 100% { opacity: 0; }
    10%, 90% { opacity: 1; }
  }