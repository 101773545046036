.corporate {
    display: flex;
    flex-wrap: wrap;
    border: 2px solid #96d31b;
    border-radius: 25px;
    font-size: 72px;
    padding: 20px;
    margin: 20px;
    text-align: justify;
    color: #fff;
    line-height: 1.1;
    letter-spacing: -0.5px;
}

.box {
    width: 300px;
    height: 325px;
    flex: 1 1 calc(33.333% - 10px);
    border-radius: 30px;
    box-sizing: border-box;
    text-align: start;
    margin: 5px;
    position: relative;
    display: flex;
    flex-direction: column;
}

.corporate .blue-background {
    background-color: transparent;
    backdrop-filter: blur(10px);
    color: white;
    justify-content: center;
    border: 1px solid #fff;
    padding: 25px;
}

.blue-background h2 {
    font-size: 30px;
    color: #fff;
    font-family: "Roboto Flex";
    font-weight: 400;
    padding: 0 25px;
}

.texto-verde {
    color: #6cc60f;
}

.image-background-1, .image-background-2, .image-background-3, .image-background-4, .image-background-5 {
    background-size: 140%;
    background-position: center;
    justify-content: center;
}

.image-background-1 {
    background-image: url('../../../assets/DC-Jack.webp');
}

.image-background-2 {
    background-image: url('../../../assets/Solda-BGA.webp');
}

.image-background-3 {
    background-image: url('../../../assets/notebook-quebrado.webp');
}

.image-background-4 {
    background-image: url('../../../assets/conserto-carregador.webp');
}

.image-background-5 {
    background-image: url('../../../assets/celular-conserto.webp');
}

.corporate button {
    font-family: "Roboto Flex";
    font-weight: 400;
    font-size: 16px;
    color: #fff;
    background-color: #6ac60f00;
    padding: 1em;
    border: solid rgb(1, 67, 167);
    border-radius: 50px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    white-space: nowrap;
    animation: pulse 5s infinite;
    margin: auto 75px 25px;
}

/* Media Queries para Responsividade */
@media (max-width: 1024px) {
    .corporate {
        font-size: 48px;
    }
}

@media (max-width: 768px) {
    .corporate {
        font-size: 36px;
    }

    .corporate button {
        font-size: 12px;
        margin: auto 25px 25px;
    }

    .box {
        flex: 1 1 calc(50% - 10px);
    }
    .blue-background h2 {
        font-size: 24px;
    }
}

@media (max-width: 480px) {
    .corporate {
        font-size: 24px;
        padding: 10px;
    }
    .box {
        flex: 1 1 100%;
    }
    .blue-background h2 {
        font-size: 18px;
        padding: 0 15px;
    }
    .corporate button {
        margin: auto 20px 10px;
    }
}
