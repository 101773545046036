@import url('https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wght@8..144,100..1000&display=swap');

body {
  margin: 0;
  background-color: rgb(1, 67, 167);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(rgb(1, 67, 167), #96d31b);
  border-radius: 6px;
}

::-moz-scrollbar {
  width: 10px;
}

::-moz-scrollbar-thumb {
  background: linear-gradient(rgb(1, 67, 167), #96d31b);
  border-radius: 6px;
}

@keyframes moveAndRotateGame {
  0% {
    transform: translateX(-20px) rotate(-20deg);
  }

  50% {
    transform: translateX(-10px) rotate(-10deg);
  }

  100% {
    transform: translateX(-20px) rotate(-20deg);
  }
}

@keyframes moveAndRotateGameController {
  0% {
    transform: translateY(20px) rotate(20deg);
  }

  50% {
    transform: translateY(10px) rotate(10deg);
  }

  100% {
    transform: translateY(20px) rotate(20deg);
  }
}

@keyframes moveAndRotateGhost {
  0% {
    transform: translateY(0) rotate(0deg);
  }

  50% {
    transform: translateY(-10px) rotate(-10deg);
  }

  100% {
    transform: translateY(0) rotate(0deg);
  }
}

@keyframes moveAndRotateLaptop {
  0% {
    transform: translateX(0) rotate(0deg);
  }

  50% {
    transform: translateX(-10px) rotate(-10deg);
  }

  100% {
    transform: translateX(0) rotate(0deg);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

button {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: bold;
  color: #fff;
  background-color: #96d31b;
  padding: 0.5em 2em;
  border: solid rgb(1, 67, 167);
  border-radius: 30px;
  position: relative;
  cursor: pointer;
  overflow: hidden;
}

button span:not(:nth-child(6)) {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 50px;
  width: 150px;
  background-color: #0c66ed;
  border-radius: 50%;
  transition: .6s ease;
}

button span:nth-child(6) {
  position: relative;
}

button span:nth-child(1) {
  transform: translate(-3.3em, -6em);
}

button span:nth-child(2) {
  transform: translate(-6em, 3.3em);
}

button span:nth-child(3) {
  transform: translate(-.2em, 2.8em);
}

button span:nth-child(4) {
  transform: translate(3.5em, 2.4em);
}

button span:nth-child(5) {
  transform: translate(3.5em, -5.8em);
}

button:hover span:not(:nth-child(6)) {
  transform: translate(-50%, -50%) scale(4);
  transition: 1.5s ease;
}