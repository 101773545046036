.footer {
  overflow: hidden;
}

.footer-img {
  display: flex;
  flex-wrap: wrap;
  border: 2px;
  border-radius: 30px;
  margin: 20px;
}

.loja-scotch {
  width: 100%;
  height: auto;
  border-radius: 30px;
}

.footer-direitos {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 150px;
}

.direitos-autorais {
  display: flex;
  justify-content: center;
  color: #fff;
  font-family: "Roboto Flex";
  font-weight: bold;
  font-size: 16px;
}

.direitos-autorais a {
  display: flex;
  justify-content: center;
  color: #e25028;
}

/* Responsivo para telas com largura máxima de 768px */
@media (max-width: 768px) {
  .footer-direitos {
    flex-direction: column;
    align-items: center;
    padding: 0 50px;
  }

  .footer-img {
    margin: 10px;
    font-size: 48px;
  }

  .direitos-autorais {
    text-align: center;
    margin: 10px 0;
  }
}

/* Responsivo para telas com largura máxima de 480px */
@media (max-width: 480px) {
  .footer-direitos {
    padding: 0 20px;
  }

  .footer-img {
    margin: 5px;
    font-size: 36px;
  }

  .direitos-autorais {
    font-size: 14px;
  }

  .direitos-autorais a {
    font-size: 14px;
  }
}
