body {
    margin: 0;
    padding: 0;
    background-color: rgb(1, 67, 167);
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  @media (max-width: 768px) {
    .home {
      overflow: hidden;
    }
  }

  @media (max-width: 480px) {
    .home {
      overflow: hidden;
    }
  }